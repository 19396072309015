body{   
    background: #eee;  
}  

.aligne{

    text-align: center;
}
#frm{  
    border: solid gray 1px;  
    width:30%;  
    border-radius: 2px;  
    float: right; 
    margin-right: 15%;
    margin-top: 10%;
    background: white;  
    padding: 50px;  
    position: absolute;
    top: -2%;
    left: 60%;
}  
#btn{  
    color: #fff;  
    background: #337ab7;  
    padding: 7px;  
    margin-left: 80%;  
} 
.btn-success {width: 100%;
}

element.style {
}
#frmDB {
  opacity: 0.9;
    border: solid gray 1px;
    width: 60%;
    border-radius: 2px;
    float: right;
    margin-right: 15%;
    margin-top: 10%;
    background: white;
    padding: 50px;
    position: absolute;
    top: -2%;
    left: 21%;
}
.bg-img{
  width: 90%;
  height: auto;
    /* background-image: url('./background_image.jpg');
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 100%; */
} 
.fade-out {
    animation: fadeOut ease 8s;
    -webkit-animation: fadeOut ease 8s;
    -moz-animation: fadeOut ease 8s;
    -o-animation: fadeOut ease 8s;
    -ms-animation: fadeOut ease 8s;
  }
  @keyframes fadeOut {
    0% {
      opacity:1;
    }
    100% {
      opacity:0;
    }
  }
  
  @-moz-keyframes fadeOut {
    0% {
      opacity:1;
    }
    100% {
      opacity:0;
    }
  }
  
  @-webkit-keyframes fadeOut {
    0% {
      opacity:1;
    }
    100% {
      opacity:0;
    }
  }
  
  @-o-keyframes fadeOut {
    0% {
      opacity:1;
    }
    100% {
      opacity:0;
    }
  }
  
  @-ms-keyframes fadeOut {
    0% {
      opacity:1;
    }
    100% {
      opacity:0;
  }
}